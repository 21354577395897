import { computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import moment from 'moment'
import { sortCompare } from './utils'

export default function useAPI () {
  // ======================================================================
  // ==================               FETCH              ==================
  // ======================================================================
  const fetchArticles = () => {
    store.dispatch('article/getArticles')
  }
  const fetchBankAccounts = () => {
    return new Promise((resolve, reject) => {
      store.dispatch('bankAccount/getBankAccounts')
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }
  const fetchCalendars = (databaseEvents) => {
    store.dispatch('calendar/getCalendars', databaseEvents)
  }
  const fetchCompanies = () => {
    store.dispatch('company/getCompanies')
  }
  const fetchEmployeeContactTypes = () => {
    store.dispatch('employeeContractType/getEmployeeContractTypes')
  }
  const fetchEvents = () => {
    store.dispatch('event/getEvents')
  }
  const fetchIncomingInvoices = () => {
    store.dispatch('incomingInvoice/getIncomingInvoices')
  }
  const fetchOrders = () => {
    store.dispatch('order/getOrders')
  }
  const fetchOutgoingInvoices = () => {
    store.dispatch('outgoingInvoice/getOutgoingInvoices')
  }
  const fetchPaymentMethods = () => {
    store.dispatch('paymentMethod/getPaymentMethods')
  }
  const fetchPaymentTerms = () => {
    store.dispatch('paymentTerm/getPaymentTerms')
  }
  const fetchOffers = () => {
    store.dispatch('offer/getOffers')
  }
  const fetchSalaries = () => {
    store.dispatch('salary/getSalaries')
  }
  const fetchSocialSecurityContributions = () => {
    store.dispatch('socialSecurityContribution/getSocialSecurityContributions')
  }
  const fetchTasks = () => {
    store.dispatch('task/getTasks')
  }
  const fetchTickets = () => {
    store.dispatch('ticket/getTickets')
  }
  const fetchUsers = () => {
    store.dispatch('user/getUsers')
  }
  const fetchVats = () => {
    store.dispatch('vat/getVats')
  }
  const fetchWorkflows = () => {
    return new Promise((resolve, reject) => {
      store.dispatch('workflow/getWorkflows')
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  // ======================================================================
  // ==================              COMPUTED            ==================
  // ======================================================================
  const articles = computed(() => {
    return store.getters['article/getArticles']
  })

  const bankAccounts = computed(() => {
    return store.getters['bankAccount/getBankAccounts']
  })

  const contacts = computed(() => {
    let output = JSON.parse(JSON.stringify(users.value.concat(companies.value)))
    let index = 0

    output.forEach(contact => {
      contact._vSelectIndex = index
      contact._vSelectId = contact.id
      index++
      delete contact.id
    })
    output.sort(sortCompare('_display'))

    return output
  })

  const contactsWithMe = computed(() => {
    let output = JSON.parse(JSON.stringify(users.value.concat(companiesWithMe.value)))
    let index = 0

    output.forEach(contact => {
      contact._vSelectIndex = index
      contact._vSelectId = contact.id
      index++
      delete contact.id
    })
    output.sort(sortCompare('_display'))

    return output
  })

  const companies = computed(() => {
    return store.getters['company/getCompaniesWithoutMainCompany']
  })

  const companiesWithMe = computed(() => {
    return store.getters['company/getCompanies']
  })

  const employeeContractTypes = computed(() => {
    return store.getters['employeeContractType/getEmployeeContractTypes']
  })

  const events = computed(() => {
    return store.getters['event/getEvents']
  })

  const incomingInvoices = computed(() => {
    return store.getters['incomingInvoice/getIncomingInvoices']
  })

  const myCompany = computed(() => {
    return store.getters['company/getMyCompany']
  })

  const orders = computed(() => {
    return store.getters['order/getOrders']
  })

  const outgoingInvoices = computed(() => {
    return store.getters['outgoingInvoice/getOutgoingInvoices']
  })

  const paymentMethods = computed(() => {
    let output = []
    store.getters['paymentMethod/getPaymentMethods'].forEach(pm => {
      output.push({
        id: pm.id,
        name: i18n.t(pm.name),
      })
    })

    return output
  })

  const paymentTerms = computed(() => {
    let output = []
    store.getters['paymentTerm/getPaymentTerms'].forEach(pt => {
      output.push({
        id: pt.id,
        term: i18n.t(pt.term),
        days: pt.days
      })
    })

    return output
  })

  const offers = computed(() => {
    return store.getters['offer/getOffers']
  })

  const salaries = computed(() => {
    return store.getters['salary/getSalaries']
  })

  const socialSecurityContributions = computed(() => {
    return store.getters['socialSecurityContribution/getSocialSecurityContributions']
  })

  const tasks = computed(() => {
    return store.getters['task/getTasks']
  })

  const tickets = computed(() => {
    return store.getters['ticket/getTickets']
  })

  const users = computed(() => {
    return store.getters['user/getUsers']
  })

  const vats = computed(() => {
    return store.getters['vat/getVats']
  })

  const workflows = computed(() => {
    return store.getters['workflow/getWorkflows']
  })

  // ======================================================================
  // ==================              METHODS             ==================
  // ======================================================================

  const getCountry = (countryCode) => {
    return store.getters['address/getCountry'](countryCode)
  }

  const getContact = (userOrCompanyEntity) => {
    let output = contacts.value.find(ct => {
      if (ct._vSelectId == userOrCompanyEntity.id) {
        if (('employees' in userOrCompanyEntity) && ('employees' in ct)) {
          return true
        } else if (!('employees' in userOrCompanyEntity) && !('employees' in ct)) {
          return true
        }
      }
    })

    if (typeof output != 'undefined') {
      return output
    } else {
      return contactsWithMe.value.find(ct => {
        if (ct._vSelectId == userOrCompanyEntity.id) {
          if ('employees' in userOrCompanyEntity && 'employees' in ct) {
            return true
          } else if (!('employees' in ct) && !('employees' in ct)) {
            return true
          }
        }
      })
    }
  }

  const getUserContract = (user, searchedDate = null) => {
    if (user.contracts.length == 0) return null
    else {
      let date = moment(searchedDate)
      if (searchedDate == null) {
        date = moment()
      }

      return user.contracts.find(c => moment(c.start) <= date && (c.end == null || date <= moment(c.end)))
    }
  }

  // const getMileageAllowanceCost = (user, distance) => {
  //   if (user.horsePower != null) {
  //     if (user.horsePower <= 3) {
  //       return parseFloat(distance) * 0.502
  //     } else if (user.horsePower == 4) {
  //       return parseFloat(distance) * 0.575
  //     } else if (user.horsePower == 5) {
  //       return parseFloat(distance) * 0.603
  //     } else if (user.horsePower == 6) {
  //       return parseFloat(distance) * 0.531
  //     } else {
  //       return parseFloat(distance) * 0.661
  //     }
  //   } else {
  //     return 0
  //   }
  // }

  return {
    // Fetchers
    fetchArticles,
    fetchBankAccounts,
    fetchCalendars,
    fetchCompanies,
    fetchEmployeeContactTypes,
    fetchEvents,
    fetchIncomingInvoices,
    fetchOrders,
    fetchOutgoingInvoices,
    fetchPaymentMethods,
    fetchPaymentTerms,
    fetchOffers,
    fetchSalaries,
    fetchSocialSecurityContributions,
    fetchTasks,
    fetchTickets,
    fetchUsers,
    fetchVats,
    fetchWorkflows,

    // Computed
    articles,
    bankAccounts,
    contacts,
    contactsWithMe,
    companies,
    employeeContractTypes,
    events,
    incomingInvoices,
    myCompany,
    orders,
    outgoingInvoices,
    paymentMethods,
    paymentTerms,
    salaries,
    socialSecurityContributions,
    tasks,
    tickets,
    users,
    offers,
    vats,
    workflows,

    // Methods
    getCountry,
    getContact,
    getUserContract,
    // getMileageAllowanceCost,
  }
}
