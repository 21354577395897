<template>
  <!--  <div>-->
  <b-form-group
      :label-for="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
      :label="displayLabel?capitalize($tc(name)):null"
      class="w-100"
  >
    <validation-provider
        v-if="isRequired"
        #default="{ errors }"
        :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
        rules="required"
    >
      <v-select
          :id="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
          :multiple="multiple"
          v-model="localModel"
          :label="label"
          :options="options"
          :reduce="label => label[reduce]"
          :placeholder="placeholder!=''?placeholder:($t('Choose a ') + $tc(name))"
          :selectable="(option) => !option.disabled"
          :clearable="clearable"
          :disabled="disabled"
          append-to-body
          class="w-100"
      >
        <template v-slot:option="option">
          <slot name="customList"
                v-bind:option="option">
            <!-- Default content -->
            <span v-if="option[reduce] == '-'">
              <b-dropdown-divider></b-dropdown-divider>
            </span>
            <span v-else>{{ name in option ? option[name] : option[label] }}</span>
          </slot>
        </template>

        <template
            v-if="allowAdd"
            #list-header
        >
          <slot name="list-header">
            <li
                @click="$emit('addCallback')"
                class="add-new-client-header d-flex align-items-center my-50 cursor-pointer"
            >
              <icon icon="plus"/>
              <span class="align-middle ml-50">{{ $t(addText) }}</span>
            </li>
          </slot>
        </template>

        <template #no-options="{ search, searching, loading }">
          {{ $t('NoResult') }}
        </template>
      </v-select>


      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>

    <v-select
        v-else
        :id="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
        :multiple="multiple"
        v-model="localModel"
        :label="label"
        :options="options"
        :reduce="label => label[reduce]"
        :placeholder="placeholder!=''?placeholder:($t('Choose a ') + $tc(name))"
        :selectable="(option) => !option.disabled"
        :clearable="clearable"
        :disabled="disabled"
        append-to-body
        class="w-100"
    >
      <template v-slot:option="option">
        <slot name="customList"
              v-bind:option="option">
          <!-- Default content -->
          <span v-if="option[reduce] == '-'">
            <b-dropdown-divider></b-dropdown-divider>
          </span>
          <span v-else>{{ name in option ? option[name] : option[label] }}</span>
        </slot>
      </template>

      <template
          v-if="allowAdd"
          #list-header
      >
        <slot name="list-header">
          <li
              @click="$emit('addCallback')"
              class="add-new-client-header d-flex align-items-center my-50 cursor-pointer"
          >
            <icon icon="plus"/>
            <span class="align-middle ml-50">{{ $t(addText) }}</span>
          </li>
        </slot>
      </template>

      <template #no-options="{ search, searching, loading }">
        {{ $t('NoResult') }}
      </template>
    </v-select>

  </b-form-group>
  <!--  </div>-->
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import { ref, computed, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import { capitalize } from '@/utils/filter'

export default {
  components: {
    vSelect,
    ValidationProvider
  },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },

    // Not required
    displayLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'label'
    },
    reduce: {
      type: String,
      default: 'id'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    allowAdd: {
      type: Boolean,
      default: false
    },
    addText: {
      type: String,
      default: 'Add'
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, () => {
      emit('update:model', localModel.value)
      emit('input', localModel.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      capitalize,
      required,

      localModel
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/vue/libs/vue-select.scss';
</style>